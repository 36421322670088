import React from 'react';
import Grids from '../Grids';
import { Grid, useMediaQuery } from '@mui/material';
import FooterContent from '../Footer/FooterContent';
import PlsLogin from '../PlsLogin';
import LandingPage from '../LandingPage';
import FF from '../FF';

const HomePage = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <div>
      <LandingPage />

      <div
        style={{
          position: "relative",
          padding: "0px",
          margin: isMobile ? "0px" : "80px 110px", 
          top: isMobile ? "50px" : "0px", 
        }}
      >
        <FF />
        <PlsLogin />
        <Grids />
      </div>

      <Grid
        item
        sx={{
          top: isMobile ? "70px" : "150px", 
          margin: isMobile ? "0px" : "auto",
          position: "relative",
        }}
        xs={12}
        md={6}
      >
        <FooterContent />
      </Grid>
    </div>
  );
};

export default HomePage;