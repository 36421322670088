import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const OngoingIpoAPI = createApi({
  reducerPath: 'ongoingIpoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://server.adityatrading.in/ipo/',
  }),
  tagTypes: ['OngoingIPO'],
  endpoints: (builder) => ({
    fetchOngoing: builder.query({
      query: () => 'ONGOING_IPO/',
      providesTags: ['OngoingIPO'],
    }),
  }),
});

export const { useFetchOngoingQuery } = OngoingIpoAPI;
export default OngoingIpoAPI;
