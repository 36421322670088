export const Index_Stocks_API = '/api/get-index-stocks/';
export const AnalystOpinion_Stocks_API = '/api/get-analyst-opinion-stocks/';
export const Market_Trends_Stocks_API = '/api/get-market-trend-stocks/';
export const MUTUAL_FUNDS_API = '/api/get-mutual-funds/';
export const ONGOING_API = 'ONGOING_IPO/';
export const UPCOMING_API = 'UPCOMING_IPO/';
export const CREATE_ALERT = '/api/CREATE_ALERT/';
export const CALENDER_API = '/api/get-earnings-calender/';
export const MORNING_REPORT_API = '/share-market-news/api/get-dailyreport/';
export const CIRCULAR_API = '/api/circulars/';
export const GRIEVANCE_API = 'investment-grievance/';
export const OTP_API = '/api/generate-OTP/';
export const CHECK_OTP_API = '/api/check-OTP/';
export const ANNUAL_REPORT_API = '/api/annual-reports/';
export const PL_API = '/api/getProfitLoss/';
export const BS_API = 'api/getBalanceSheet/';
export const CASH_FLOW_API = '/api/getCashFlow/';
export const RATIO_API = '/api/getRatios/';
export const SHARE_HOLDING_API = 'api/getShareHoldingReport/';
export const PORTFOLIO_API = '/portfolioOperation/';
export const USER_PROFILE_API = '/userProfile/';
// export const USER_PROFILE_API = '/authentication/get-current-user/';
export const MOBILE_API = 'api/sdk-signin-mobile';
export const EMAIL_API = 'api/sdk-signin-email';
export const BANK_API = '/api/bank-modification';
export const PLEDGE_API = '/api/client-holdings';
export const UPDATE_CLIENT_INCOME = 'update-client-annual-income';
export const SEGMENT_ADDITION = 'segmentAddition';
export const ADD_NOMINEE = '/addNominee';

//rights and obligations -> https://adityatrading.in/media/rightsandobligations/rights-and-obligations.pdf


//https://server.adityatrading.in/ipo/UPCOMING_IPO/
//https://server.adityatrading.in/ipo/ONGOING_IPO/