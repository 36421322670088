import React, { useEffect, useState } from 'react';
import './Component.css';
import classNames from 'classnames';
import { ArrowDownward } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useGetAnalystQuery } from '../redux/Analyst';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/system';
import { FaCaretDown } from 'react-icons/fa';

const Currency = () => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  const [socket, setSocket] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected index
  const [wsData, setWsData] = useState([]);
  const handleDetails = (index) => {
    setSelectedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle the selected index
  };

  const theme = useTheme();

  // Add a fallback media query string if theme.breakpoints or down method is not available
  const isMobile = useMediaQuery(theme?.breakpoints?.down('sm') || '(max-width:600px)'); 
  //error here?


  const { data: analystdata } = useGetAnalystQuery();
  const intraday = (analystdata && analystdata['currencyIntraday']) || []; // Handle case where intraday might be undefined

  useEffect(() => {
    const ws = new WebSocket('wss://server.adityatrading.in/ws/market-data/');
    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      // Send subscription messages for all tokenIDs
      if (intraday.length > 0) {
        intraday.forEach((stock) => {
          const message = JSON.stringify({
            event: 'addTicker',
            tokenId: stock.tokenID,
          });
          ws.send(message);
          console.log(`Sent tokenID ${stock.tokenID} to WebSocket.`);
        });
      }
    };

    ws.onmessage = (event) => {
      const validJsonString = event.data.replace(/'/g, '"');
      const data = JSON.parse(validJsonString);

        setWsData((prevData) => {
            const updatedData = prevData.filter(
                (item) => item.tokenID !== data.tokenID
            );
            updatedData.push(data);
            return updatedData;
        });
    };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.log('WebSocket error:', error);

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [intraday]);

  const combinedStocks = intraday.map((stock) => {
    const updatedStock = wsData.find((data) => data.tokenID === stock.tokenID);
    return updatedStock ? { ...stock, ...updatedStock } : stock;
  });

  return (
    <div>
      <div className="politicalnewscontainer">
        {combinedStocks.map((intra, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexWrap: "nowrap",
              flexDirection: "column",
              padding: "10px",
              marginBottom: "10px",
              boxShadow: "0 0.115rem 0.25rem rgba(0, 0, 0, 0.075)",
              borderRadius: "0.25rem",
              backgroundColor: "#fff",
              gap: "1rem",
            }}
          >
            {/* Main Row */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "15px",
              }}
            >
              {/* Logo and Ticker */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  flexBasis: "45%",
                }}
              >
                <img
  src={intra.logoUrl}
  alt="Nifty"
  style={{
    width: "40px",
    height: "40px",
    objectFit: "contain", // Ensures the image is not cropped
    backgroundColor: "#f0f0f0", // Optional: Add background color for empty space
  }}
/>

                <div>
                  <p
                    style={{
                      fontSize: "clamp(0.75rem, 1vw, 1rem)",
                      fontWeight: 600,
                      margin: 0,
                      textTransform: "uppercase",
                    }}
                  >
                    {intra.ticker_code}
                  </p>
                  <p
                    style={{
                      fontSize: "clamp(0.7rem, 0.9vw, 1rem)",
                      margin: 0,
                      color: "#777D74",
                    }}
                  >
                    {intra.status}
                  </p>
                </div>
              </div>
  
              {/* Buy Button */}
              <div
                style={{
                  padding: "4px 6px",
                  borderRadius: "8px",
                  backgroundColor: intra.Change > 0 ? "#d1ffdd" : "#ffdfe2",
                  color: intra.Change > 0 ? "#34a853" : "#dc3545",
                  fontSize: "clamp(0.7rem, 0.8vw, 0.9rem)",
                  cursor: "pointer",
                  textAlign: "center",
                  flexBasis: "14%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleNavigation(intra.ticker_code, intra.tokenID)}
              >
                Buy
              </div>
  
              {/* CMP and Change */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  textAlign: "right",
                  flexBasis: "30%",
                }}
              >
                <p
                  style={{
                    fontSize: "clamp(0.7rem, 1vw, 1rem)",
                    margin: 0,
                    color: "#777D74",
                    fontWeight: 500,
                  }}
                >
                  {intra.LTP || "N/A"}
                </p>
                <p
                  style={{
                    display: "flex",
                    gap: "5px",
                    margin: 0,
                    fontSize: "clamp(0.7rem, 0.8vw, 0.9rem)",
                  }}
                >
                  <span
                    style={{
                      color: intra.Change <= 0 ? "#dc3545" : "#34a853",
                    }}
                  >
                    {intra.Change !== undefined ? intra.Change.toFixed(2) : ""}
                  </span>
                  <span
                    style={{
                      color: intra.percentageChange <= 0 ? "#dc3545" : "#34a853",
                    }}
                  >
                    {intra.percentageChange !== undefined
                      ? `(${intra.percentageChange})`
                      : "N/A"}
                  </span>
                </p>
              </div>
  
              {/* Dropdown Icon */}
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handleDetails(index)}
              >
                <FaCaretDown />
              </div>
            </div>
  
            {/* Dropdown Content */}
            {selectedIndex === index && (
              <div
                style={{
                  marginTop: "0px",
                  padding: "0px",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  boxShadow: "0 0.005rem 0.15rem rgba(0, 0, 0, 0.075)",
                }}
              >
                <table style={{ width: "80%", fontSize: "clamp(0.6rem, 0.7vw, 0.8rem)", color: "#212529" }}>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Entry Price</th>
                      <th>Target</th>
                      <th>Stop Loss</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{new Date(intra.created).toLocaleDateString()}</td>
                      <td>{intra.entryPrice}</td>
                      <td>{intra.targetPrice}</td>
                      <td>{intra.stopLossPrice}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Currency;
