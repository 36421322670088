import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import '../App.css';
import classNames from 'classnames';
import '../Todays Financial News/Politicalnews.css';
import { useGetTrendsQuery } from '../redux/Markettrends';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setTokenID } from '../reduxslices/tokenSlices';


const Lossers = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleNavigation = (companyName, tokenID) => {
    console.log("IntraDay ----->>>>>>", tokenID);
    dispatch(setTokenID({ companyName, tokenID }));
    navigate(`/companyPage/${companyName}`);
  };

  const [socket, setSocket] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected index
  const [wsData, setWsData] = useState([]);
  const { data: trendData } = useGetTrendsQuery();

  const mostactive = (trendData && trendData['losersStocks']) || [];
  useEffect(() => {
    const ws = new WebSocket('wss://server.adityatrading.in/ws/market-data/');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      // Send subscription messages for all tokenIDs
      if (mostactive.length > 0) {
        mostactive.forEach((stock) => {
          const message = JSON.stringify({
            event: 'addTicker',
            tokenId: stock.tokenID,
          });
          ws.send(message);
          //console.log(`Sent tokenID ${stock.tokenID} to WebSocket.`);
        });
      }
    };

    ws.onmessage = (event) => {
      const validJsonString = event.data.replace(/'/g, '"');
      const data = JSON.parse(validJsonString);
    

      setWsData((prevData) => {
        const updatedData = prevData.filter(
          (item) => item.tokenID !== data.tokenID
        );
        updatedData.push(data);
        return updatedData;
      });
    };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.log('WebSocket error:', error);

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, [mostactive]);
  const combinedStocks = mostactive.map((stock) => {
    const updatedStock = wsData.find((data) => data.tokenID === stock.tokenID);
    return updatedStock ? { ...stock, ...updatedStock } : stock;
  });
  console.log(combinedStocks);
  return (
    <div>
      <div
        className={classNames('politicalnewscontainer', 'scrollable-container')}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: '-12px',
          }}
        >
          {' '}
          {combinedStocks.map((stock, index) => (
            <div
              key={index}
              style={{
                padding: '0.7rem',
                marginTop: '0.5rem',
                boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
                borderRadius: '0.25rem',
                width: '92%',
                marginLeft: '20px',
                boxSizing: 'border-box',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    minWidth: '150px',
                  }}
                >
                  <div
                    style={{
                      width: '50px',
                      height: '50px',
                      padding: '3px',
                      border: '1px solid #eeeeee',
                      borderRadius: '6px',
                      marginRight: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={stock.logoUrl}
                      alt="Nifty"
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: '0.75rem',
                        color: '#3c4043',
                        fontWeight: 600,
                        marginBottom: '0px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {stock.ticker_code}
                    </p>
                    <p
                      style={{
                        marginBottom: '0px',
                        fontSize: '12px',
                        fontWeight: 600,
                        marginTop: '0px',

                        color: '#777D74',
                        fontFamily: 'sans-serif',
                      }}
                    >
                      CMP:
                      <span
                        style={{
                          fontSize: '12px',
                          fontWeight: 600,
                          color: '#777D74',
                        }}
                      >
                        {' '}
                        {stock.LTP}
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '10px 0',
                    height: '20px',
                    width: '10%',
                  }}
                  onClick={() => handleNavigation(stock.ticker_code, stock.tokenID)}
                >
                  <p
                    className={
                      stock.Change <= 0 ? 'redbg-text' : 'greenbg-text'
                    }
                    style={{
                      padding: '0 12px',

                      fontSize: '12px',
                      cursor: 'pointer',
                      borderRadius: '7px',
                      height: '20px',
                      width: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Buy
                  </p>
                </div>

                <p
                  className={stock.Change <= 0 ? 'red-text' : 'green-text'}
                  style={{
                    color: '#34a853',
                    fontSize: '14px',
                    fontWeight: 400,
                    marginRight: '10px',
                    marginTop: '0px',
                  }}
                >
                  {stock.Change !== undefined ? stock.Change.toFixed(2) : 'N/A'}
                </p>

                <p
                  className={
                    stock.percentageChange <= 0 ? 'redbg-text' : 'greenbg-text'
                  }
                  style={{
                    padding: '0 12px',
                    // backgroundColor: stock.Change > 0 ? '#d1ffdd' : '#ffdfE2',
                    // color:
                    //   stock.Change > 0
                    //     ? '#34a853!important'
                    //     : '#dc3545!important',
                    fontSize: '10px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '70px',
                    height: 'auto',
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    marginTop: '0px',
                    justifyContent: 'center',
                  }}
                >
                  {stock.percentageChange < 0 ? (
                    <ArrowDownward
                      sx={{ height: '12px', position: 'relative' }}
                    />
                  ) : (
                    <ArrowUpward
                      sx={{ height: '12px', position: 'relative' }}
                    />
                  )}{' '}
                  {stock.percentageChange}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Lossers;
