import React, { useEffect } from 'react';
import '../App.css';
import '../Todays Financial News/Politicalnews.css';
import { Button } from '@mui/material';
import { useFetchOngoingQuery } from '../redux/onGoingIpo';


const OngoingIpo = () => {
  const { data: ongoingIpos = [], error, isLoading } = useFetchOngoingQuery();


  console.log("here is the Ongoing IpO data -->",ongoingIpos.message);
  console.error("here is the Ongoing IpO Error -->",ongoingIpos.status);

  const handleApplyClick = (redirectUrl) => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      console.error('No redirect URL provided.');
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown Date';
    const date = new Date(dateString);
    return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
  };

  if (isLoading) return <p>Loading ongoing IPOs...</p>;
  if (error) return <p style={{ color: 'red' }}>Error: {error?.data?.message || 'Failed to load data.'}</p>;

  return (
    <div className="scrollable-container">
      {ongoingIpos.length > 0 ? (
        ongoingIpos.map((ipo, index) => (
          <div
            key={index}
            className="ipo-card"
            style={{
              padding: '10px',
              borderRadius: '10px',
              marginBottom: '15px',
              boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
              maxWidth: '600px',
              margin: '0 auto',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={ipo.logoUrl || '/default-logo.png'}
                  alt="IPO Logo"
                  style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '6px' }}
                />
                <div>
                  <p style={{ fontSize: '14px', fontWeight: 'bold', margin: 0 }}>{ipo.ipoName || 'Unknown IPO'}</p>
                  <p style={{ margin: 0, fontSize: '12px', color: '#666' }}>{ipo.sharesPerLot} Shares</p>
                </div>
              </div>
              <div>
                <p style={{ fontSize: '12px', fontWeight: 600 }}>₹{ipo.priceRangeFrom} - ₹{ipo.priceRangeTo}</p>
                <p style={{ fontSize: '10px', color: '#888' }}>Price range</p>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <p style={{ fontSize: '12px', fontWeight: 600 }}>{formatDate(ipo.issueDateFrom)} - {formatDate(ipo.issueDateTo)}</p>
              <Button
                onClick={() => handleApplyClick(ipo.redirectUrl)}
                disabled={!ipo.redirectUrl}
                sx={{
                  padding: '5px 15px',
                  backgroundColor: ipo.redirectUrl ? '#d1ffdd' : '#f0f0f0',
                  color: ipo.redirectUrl ? '#34a853' : '#888',
                  fontSize: '12px',
                  '&:hover': { backgroundColor: ipo.redirectUrl ? '#b4f9c2' : '#f0f0f0' },
                }}
              >
                Apply
              </Button>
            </div>
          </div>
        ))
      ) : (
        <p>No ongoing IPOs available.</p>
      )}
    </div>
  );
};

export default OngoingIpo;


// const OngoingIpo = () => {
//   const { data: onGoingData } = useOnGoingQuery();
//   const Debt = onGoingData?.ongoingIPO || [];

//   // Function to handle the redirection
//   const handleApplyClick = (redirectUrl) => {
//     if (redirectUrl) {
//       window.location.href = redirectUrl; // Redirect to the URL
//     } else {
//       console.error('No redirect URL provided.');
//     }
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   return (
//     <div className="scrollable-container">
//       {Debt.length > 0 ? (
//         Debt.map((ipo, index) => (
//           <div
//             key={index}
//             style={{
//               padding: '10px',
//               borderRadius: '10px',
//               marginBottom: '15px',
//               boxShadow: '0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)',
//               display: 'flex',
//               flexDirection: 'column',
//               width: '100%',
//               boxSizing: 'border-box',
//               maxWidth: '600px', // You can adjust max width for larger screens
//               margin: '0 auto', // Center it on larger screens
//             }}
//           >
//             {/* First Row: Icon, Name, and Shares */}
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//                 marginBottom: '10px',
//               }}
//             >
//               {/* Icon and Name */}
//               <div style={{ display: 'flex', alignItems: 'center' }}>
//                 <div
//                   style={{
//                     width: '50px',
//                     height: '50px',
//                     padding: '3px',
//                     border: '1px solid #eeeeee',
//                     borderRadius: '6px',
//                     marginRight: '10px',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                   }}
//                 >
//                   <img
//                     src={ipo.logoUrl || "https://neuron.adityatrading.in/static/app-assets/images/logo/ats-logo.png"}
//                     alt="IPO Logo"
//                     style={{ maxWidth: '100%', maxHeight: '100%' }}
//                   />
//                 </div>
//                 <div>
//                   <p
//                     style={{
//                       fontSize: '14px',
//                       fontWeight: 'bold',
//                       margin: 0,
//                       color: '#282828',
//                     }}
//                   >
//                     {ipo.ipoName}
//                   </p>
//                   <p style={{ margin: 0, fontSize: '12px', color: '#666' }}>
//                     {ipo.sharesPerLot} Shares
//                   </p>
//                 </div>
//               </div>
//               {/* Share Info */}
//               <div>
//                 <p
//                   style={{
//                     fontSize: '12px',
//                     color: '#282828',
//                     fontWeight: 600,
//                   }}
//                 >
//                   ₹{ipo.priceRangeFrom} to ₹{ipo.priceRangeTo}
//                 </p>
//                 <p style={{ marginBottom: 0, fontSize: '10px', color: '#888' }}>
//                   Price range
//                 </p>
//               </div>
//             </div>

//             {/* Second Row: Price Range, Issue Date, Apply Button */}
//             <div
//               style={{
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//                 flexWrap: 'wrap',
//               }}
//             >
//               {/* Issue Date */}
//               <div>
//                 <p
//                   style={{
//                     fontSize: '12px',
//                     color: '#282828',
//                     fontWeight: 600,
//                   }}
//                 >
//                   {formatDate(ipo.issueDateFrom)} to {formatDate(ipo.issueDateTo)}
//                 </p>
//                 <p style={{ marginBottom: 0, fontSize: '10px', color: '#888' }}>
//                   Issue date
//                 </p>
//               </div>

//               {/* Apply Button */}
//               <Button
//                 onClick={() => handleApplyClick(ipo.redirectUrl)} // Add click handler for redirection
//                 disabled={!ipo.redirectUrl} // Disable if no redirect URL
//                 sx={{
//                   padding: '5px 15px',
//                   backgroundColor: ipo.redirectUrl ? '#d1ffdd' : '#f0f0f0', // Change color if disabled
//                   color: ipo.redirectUrl ? '#34a853' : '#888',
//                   fontSize: '12px',
//                   cursor: ipo.redirectUrl ? 'pointer' : 'not-allowed',
//                   borderRadius: '4px',
//                   textTransform: 'none', // To prevent default uppercase styling
//                   '&:hover': {
//                     backgroundColor: ipo.redirectUrl ? '#b4f9c2' : '#f0f0f0',
//                   },
//                 }}
//               >
//                 Apply
//               </Button>
//             </div>
//           </div>
//         ))
//       ) : (
//         <h5 style={{ textAlign: 'center', marginTop: '20px', color: '#555' }}>
//           No ongoing IPO
//         </h5>
//       )}
//     </div>
//   );
// };

