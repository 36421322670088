import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const UpcomingIpoAPI = createApi({
  reducerPath: 'upcomingIpoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://server.adityatrading.in/ipo/',
  }),
  tagTypes: ['UpcomingIPO'],
  endpoints: (builder) => ({
    fetchUpcoming: builder.query({
      query: () => 'UPCOMING_IPO/',
      providesTags: ['UpcomingIPO'],
    }),
  }),
});

export const { useFetchUpcomingQuery } = UpcomingIpoAPI;
export default UpcomingIpoAPI;
