import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { useSearchStocksMutation } from './redux/searchBox'; // Update import path if needed
import { CardContent, Grid, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setTokenID } from './reduxslices/tokenSlices';
import { useLocation, useNavigate } from 'react-router';
import StockModel from './CreatePortfolio/StockModel';

const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: '400px',
  },
  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '280px',
    },
  },
}));

export default function SearchBox() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState('');
  const [stocks, setStocks] = React.useState([]);
  const [socket, setSocket] = React.useState(null);
  const [wsData, setWsData] = React.useState([]);
  const [searchValueTicker, setSearchValueTicker] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [searchStocks, { data: stockdata, error, isLoading }] =
    useSearchStocksMutation();
  const intraday = (stockdata && stockdata['stockSearch']) || [];

  // WebSocket setup
  React.useEffect(() => {
    const ws = new WebSocket('wss://server.adityatrading.in/ws/market-data/');

    ws.onopen = () => {
      console.log('WebSocket connection opened.');
      searchValueTicker.forEach((tokenID) => {
        const message = JSON.stringify({
          event: 'deleteTicker',
          token: tokenID,
        });
        ws.send(message);
        console.log(`Sent tokenID ${tokenID} to WebSocket.`);
      });
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      setWsData((prevData) => {
        const updatedData = prevData.filter(
          (item) => item.tokenID !== data.tokenID
        );
        updatedData.push(data);
        return updatedData;
      });
    };

    ws.onclose = () => console.log('WebSocket connection closed.');
    ws.onerror = (error) => console.log('WebSocket error:', error);

    setSocket(ws);

    return () => {
      ws.close();
    };
  }, []); // Notice the empty dependency array here

  const combinedStocks = intraday.map((stock) => {
    const updatedStock = wsData.find((data) => data.tokenID === stock.tokenID);
    return updatedStock ? { ...stock, ...updatedStock } : stock;
  });

  // Memoized debounce function
  const debouncedSearchStocks = React.useCallback(
    debounce((query) => {
      if (query) {
        searchStocks(query);
      }
    }, 300),
    [searchStocks]
  );

  const handleInputChange = React.useCallback(
    (e) => {
      const newValue = e.target.value.toUpperCase();
      setInputValue(newValue);

      if (newValue) {
        // Only search if the input is not empty
        debouncedSearchStocks(newValue);
      } else {
        // Clear stockdata if input is empty
        setInputValue('');
        searchStocks(''); // Optional: Trigger an empty search to reset state
      }
    },
    [debouncedSearchStocks, searchStocks]
  );

  // Function to send messages once WebSocket is open
  const sendMessageOnOpen = React.useCallback((ws, message) => {
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(message);
    } else {
      // Retry after 100ms if still connecting
      setTimeout(() => sendMessageOnOpen(ws, message), 100);
    }
  }, []);

  // Update searchValueTicker and send WebSocket message when intraday data updates
  React.useEffect(() => {
    if (intraday.length > 0 && socket) {
      const tokens = intraday.map((item) => item.tokenID);
      setSearchValueTicker((prev) => {
        if (JSON.stringify(prev) !== JSON.stringify(tokens)) {
          tokens.forEach((tokenID) => {
            const message = JSON.stringify({
              event: 'addTicker',
              token: tokenID,
            });
            sendMessageOnOpen(socket, message);
          });
        }
        return tokens;
      });
    }
  }, [intraday, socket, sendMessageOnOpen]); // Correctly list dependencies here
  const formatChange = (value) => Number(value).toFixed(2);
  const handleModelOpen = () => {
    console.log('trigerd1');
    setOpen(!open);
  };

  const handleNavigation = (companyName, tokenID) => {
    console.log('trigerd');

    dispatch(setTokenID({ companyName, tokenID }));
    if (location.pathname == '/') {
      console.log("I have clicked on the stock --------  1");
      console.log("I have clicked on the stock --------  2");
      console.log("I have clicked on the stock --------  3");
      navigate(`/companyPage/${companyName}`);
      console.log("Navigation to the page --------  4")
    } else {
      console.log("Page Not Opening --------  5")
      handleModelOpen();
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Box
        sx={{
          flexGrow: 1,
          justifyContent: 'center',
          display: 'grid',
          width: 'auto',
          position: 'absolute',
          marginTop: '30px',
        }}
      >
        <Search sx={{ borderRadius: '15px' }}>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search Stocks, Mutual Funds"
            inputProps={{ 'aria-label': 'search' }}
            value={inputValue}
            onChange={handleInputChange}
          />
        </Search>

        {/* Handling error and loading states */}
        {isLoading && <Typography>Loading...</Typography>}
        {error && <Typography color="error">Error fetching data</Typography>}
        {intraday.length > 0 && (
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: 'center',
              display: 'grid',
              width: '342px',
              position: 'relative',
              height: '263px',
              overflowX: 'hidden',
              marginTop: '50px',
              zIndex: '39',
              boxShadow: '0 .125rem .25rem rgba(0, 0, 0, .075) !important',
              backgroundColor: '#fff',
            }}
          >
            <Grid container sx={{ justifyContent: 'center' }}>
              {combinedStocks.map((stock) => (
                <Grid item xs={12} key={stock.tokenID}>
                  <CardContent
                    sx={{
                      width: '294px',
                      height: '74px',
                      border: '1px solid #eeeeee',
                      borderRadius: '10px',
                      padding: '8px 12px',
                      marginBottom: '10px!important',
                      margin: 'auto',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            backgroundColor:
                              stock.percentageChange && stock.Change < 0
                                ? '#ffdfe2'
                                : '#d1ffdd',
                            borderRadius: '5px',
                            textAlign: 'center',
                            width: '32px',
                            height: '32px',
                          }}
                        >
                          {stock.percentageChange && stock.Change < 0 ? (
                            <ArrowDownward
                              sx={{
                                marginTop: '8px',
                                fontSize: '14px',
                                color: '#dc3545',
                              }}
                            />
                          ) : (
                            <ArrowUpward
                              sx={{
                                marginTop: '8px',
                                fontSize: '14px',
                                color: '#34a853',
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'baseline',
                        }}
                      >
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ fontWeight: 'bold', fontSize: '14px' }}
                        >
                          {stock.ticker_code} {stock.exchange}
                        </Typography>
                        <Typography
                          onClick={() =>
                            handleNavigation(stock.ticker_code, stock.tokenID)
                          }
                          sx={{
                            fontSize: '.75rem',
                            fontWeight: 600,
                            textTransform: 'uppercase',
                          }}
                          component="p"
                        >
                          {stock.LTP}
                        </Typography>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: '12px',
                          }}
                        >
                          <span
                            style={{
                              color:
                                isNaN(stock.percentageChange) ||
                                  stock.percentageChange >= 0
                                  ? '#34a853' // Green color for 0 or positive values
                                  : '#dc3545', // Red color for negative values
                            }}
                          >
                            {isNaN(stock.percentageChange) ||
                              stock.percentageChange === undefined
                              ? '0%'
                              : `${stock.percentageChange}%`}
                          </span>
                        </p>
                        <p style={{ fontSize: '12px' }}>
                          <span
                            style={{
                              color:
                                isNaN(stock.Change) || stock.Change >= 0
                                  ? '#34a853' // Green color for 0 or positive values
                                  : '#dc3545', // Red color for negative values
                            }}
                          >
                            {isNaN(stock.Change) || stock.Change === undefined
                              ? '+0'
                              : stock.Change >= 0
                                ? `+${formatChange(stock.Change)}`
                                : `${formatChange(stock.Change)}`}
                          </span>
                        </p>
                      </div>
                    </div>
                  </CardContent>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>
      <StockModel open={open} handleOpen={handleModelOpen} />
    </div>
  );
}
