import React, { useState, useEffect } from 'react';
import LandingPage from '../../LandingPage';
import { Box, Button, Grid } from '@mui/material';
import FooterContent from '../../Footer/FooterContent';
import SelectButton from '../../Buttons/SelectButton';
import Personalinfo from './Personalinfo';
import Reactivation from './Reactivation';
import Brokerage from './Brokerage';
import Bank from './Bank';
import POA from './POA';
import MTF from './MTF';
import FATCA from './FATCA';
import Nominee from './Nominee';
import Pledgeunpludge from './Pledgeunpludge';
import Segment from './Segment';
import Closure from './Closure';
import Contract from './Contract';
import Annual from './Annual';
import Freeze from './Freeze';
import { useUserprofileQuery } from '../../redux/Userprofile';
import Cookies from 'js-cookie';

// const getCsrfTokenFromCookie = () => {
//   const csrfToken = Cookies.get("csrftoken");
//   //console.log("This is cookie -> ", Cookies)
//   console.log("CSRF Token:", csrfToken);
//   return csrfToken || "NO CSRF TOKEN FOUND";
// };

const UserProfile = () => {
  const [clientCode, setClientCode] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [userName, setUserName] = useState('');
  const [selected, setSelected] = useState('');
  //const { data: profileDatas } = useUserprofileQuery();
  

  useEffect(() => {
    const storedData = localStorage.getItem("verifiedData");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData.data) setClientCode(parsedData.data);
        if (parsedData.Phone_Number) setPhoneNumber(parsedData.Phone_Number);
        console.log("Client Code from stored data:", parsedData.data);
      } catch (error) {
        console.error("Error parsing verifiedData:", error);
      }
    } else {
      console.warn("No verifiedData found in localStorage.");
    }
  }, []);
  
  useEffect(()=>{
    console.log("clientcode -- ", clientCode);
    console.log("phone_number -- ", phonenumber);
  }, []);

  const renderComponent = () => {
    switch (selected) {
      case 'PersonalInfo':
        return <Personalinfo />;
      case 'Reactivation':
        return <Reactivation />;
      case 'Broker':
        return <Brokerage />;
      case 'Bank':
        return <Bank />;
      case 'POA':
        return <POA mobileNumber={phonenumber} />;
      case 'MTF':
        return <MTF mobileNumber={phonenumber} />;
      case 'FATCA':
        return <FATCA />;
      case 'Nominee':
        return <Nominee mobileNumber={phonenumber} clientCode={clientCode} />;
      case 'PledgeUnpledge':
        return <Pledgeunpludge />;
      case 'Segment':
        return <Segment />;
      case 'Closure':
        return <Closure mobileNumber={phonenumber} />;
      // case 'Contract':
      //   return <Contract />;
      case 'Annual':
        return <Annual />;
      case 'freeze':
        return <Freeze clientCode={clientCode} />;
      default:
        return <Personalinfo />;
    }
  };

  return (
    <div>
      <LandingPage />
      <div
        style={{
          position: 'relative',
          padding: '0px',
          margin: '0px',
          top: '90px',
        }}
      >
        <Button
          sx={{
            color: '#34a853!important',
            backgroundColor: '#d1ffdd!important',
            borderRadius: '30px',
            border: '1px solid #e5f2ff!important',
            paddingTop: '10px',
            paddingLeft: '12px',
            paddingRight: '12px',
            paddingBottom: '10px',
            fontSize: '14px',
            fontWeight: 600,
            marginBottom: '10px',
            marginLeft: '15px',
            textTransform: 'none',
            left: '100px',
          }}
        >
          Profile
        </Button>
        <Box sx={{ width: '80%', margin: 'auto' }}>
          <Box sx={{ position: 'relative', left: '8px', marginTop: '10px' }}>
            <h5>Hello <span style={{ color: '#34a853' }}>{userName}</span></h5>
            <h5>
              Client Code <span style={{ color: '#34a853' }}>{clientCode}</span>
            </h5>
          </Box>
          <SelectButton onClick={() => setSelected('PersonalInfo')}>
            PersonalInfo
          </SelectButton>
          <SelectButton onClick={() => setSelected('Reactivation')}>
            Reactivation
          </SelectButton>
          <SelectButton onClick={() => setSelected('Broker')}>
            Brokerage Info
          </SelectButton>
          <SelectButton onClick={() => setSelected('Bank')}>
            Bank/DP
          </SelectButton>
          <SelectButton onClick={() => setSelected('POA')}>
            Activate POA/DDPI
          </SelectButton>
          <SelectButton onClick={() => setSelected('MTF')}>
            Activate MTF
          </SelectButton>
          <SelectButton onClick={() => setSelected('FATCA')}>
            FATCA
          </SelectButton>
          <SelectButton onClick={() => setSelected('Nominee')}>
            Nominee
          </SelectButton>
          <SelectButton onClick={() => setSelected('PledgeUnpledge')}>
            Pledge/Unpledge
          </SelectButton>
          <SelectButton onClick={() => setSelected('Segment')}>
            Segment Addition
          </SelectButton>
          <SelectButton onClick={() => setSelected('Closure')}>
            Closure
          </SelectButton>
          {/* <SelectButton onClick={() => setSelected('Contract')}>
            Contract Note
          </SelectButton> */}
          <SelectButton onClick={() => setSelected('Annual')}>
            Annual Income
          </SelectButton>
          <SelectButton onClick={() => setSelected('freeze')}>
            Account Freeze/Unfreeze
          </SelectButton>
          <div>{renderComponent()}</div>
        </Box>
        <Grid item sx={{ top: '70px', position: 'relative' }} xs={12} md={6}>
          <FooterContent />
        </Grid>
      </div>
    </div>
  );
};

export default UserProfile;
