import React, { useState } from 'react';
import MostActive from './MostActive';
import Gainer from './Gainer';
import Lossers from './Lossers';
import { Button } from '@mui/material';

const MarketTrends = () => {
  const [selectedComponent, setSelectedComponent] = useState('Gainers');

  const renderComponent = () => {
    switch (selectedComponent) {
      case 'Gainers':
        // return <MostActive />;
        return <Gainer />;
      case 'Losers':
        return <Lossers />;
      default:
        return <Gainer />;
    }
  };

  const getButtonStyles = (component) => ({
    color: selectedComponent === component ? '#ffffff' : '#000000', // White text when selected, black when unselected
    backgroundColor: selectedComponent === component ? '#34a853' : 'transparent', // Green background when selected, transparent when unselected
    borderRadius: '50px', // Rounded button
    border: '1px solid #34a853', // Green border for both selected and unselected
    padding: '4px 8px', // Larger padding for better spacing
    fontSize: selectedComponent === component ? '13px' : '11px', // Bigger font when selected
    fontWeight: 600, // Bold text
    marginBottom: '10px',
    marginLeft: '10px',
    textTransform: 'none',
    cursor: 'pointer',
    transition: 'transform 0.5s ease, background-color 0.6s ease, color 0.6s ease, font-size 0.6s ease', // Smooth transitions
    transform: selectedComponent === component ? 'scale(1.08)' : 'scale(1)', // Slightly bigger button when selected
    '&:hover': {
      backgroundColor: selectedComponent === component ? '#34a853' : '#d1ffdd', // Hover background color for unselected buttons
      transform: 'scale(1.1)', 
    },
    '&:active': {
      transform: 'scale(0.9)',
    },
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '1px solid #cfc2c2',
        borderRadius: '10px',
        minHeight: '350px',
        maxHeight: '590px',
        overflowY: 'auto',
      }}
    >
      <h5
        style={{
          fontSize: '20px',
          fontWeight: 400,
          letterSpacing: '.3px',
          color: '#131722',
          lineHeight: '1.5px',
          paddingLeft: '20px',
          marginTop: '25px',
          textAlign: 'center',
        }}
      >
        Market Trends
      </h5>

      <div
        style={{
          width: '100%',

          marginTop: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          {/* <Button
            onClick={() => setSelectedComponent('MostActive')}
            sx={getButtonStyles('MostActive')}
          >
            MostActive
          </Button> */}
          <Button
            onClick={() => setSelectedComponent('Gainers')}
            sx={getButtonStyles('Gainers')}
          >
            Gainers
          </Button>
          <Button
            onClick={() => setSelectedComponent('Losers')}
            sx={getButtonStyles('Losers')}
          >
            Losers
          </Button>
        </div>
        <hr style={{ marginLeft: '0px' }} />
        {renderComponent()}
      </div>
    </div>
  );
};

export default MarketTrends;
