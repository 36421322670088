import React, { useEffect, useState } from 'react';
import './Component.css';
import classNames from 'classnames';
import { ArrowDownward } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useGetAnalystQuery } from '../redux/Analyst';
import { FaCaretDown } from 'react-icons/fa';

const Commodity = () => {
    const navigate = useNavigate();
    const handleNavigation = (path) => {
        console.log('navigation path --> ', path);
        navigate(path);
    };

    const [socket, setSocket] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null); // Track the selected index
    const [wsData, setWsData] = useState([]);
    const handleDetails = (index) => {
        setSelectedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle the selected index
    };

    const { data: analystdata } = useGetAnalystQuery();
    const intraday = (analystdata && analystdata['commodityIntraday']) || []; // Handle case where intraday might be undefined

    useEffect(() => {
        const ws = new WebSocket('wss://server.adityatrading.in/ws/market-data/');

        ws.onopen = () => {
            console.log('WebSocket connection opened.');
            if (intraday.length > 0) {
                intraday.forEach((stock) => {
                    const message = JSON.stringify({
                        event: 'addTicker',
                        tokenId: stock.tokenID,
                    });
                    ws.send(message);
                    console.log("Sending WebSocket subscription message:", {
                        event: 'addTicker',
                        tokenId: stock.tokenID,
                    });
                });
            }
        };

        ws.onmessage = (event) => {
            console.log("Raw WebSocket data in commodity:", event.data);
            try {
                // Sanitize and parse the WebSocket message
                const sanitizedData = event.data.replace(/'/g, '"');
                const data = JSON.parse(sanitizedData);

                if (data && data.tokenID) {
                    // Process the valid data
                    setWsData((prevData) => {
                        const existingStockIndex = prevData.findIndex(
                            (item) => item.tokenID === data.tokenID
                        );
                        if (existingStockIndex !== -1) {
                            const updatedData = [...prevData];
                            updatedData[existingStockIndex] = {...updatedData[existingStockIndex], ...data };
                            return updatedData;
                        } else {
                            return [...prevData, data];
                        }
                    });
                } else {
                    console.warn("WebSocket commodity required fields:", data);
                }
            } catch (error) {
                console.error("Error parsing commodity WebSocket message:", error.message, event.data);
            }
        };


        ws.onclose = () => console.log('WebSocket connection closed.');
        ws.onerror = (error) => console.log('WebSocket error:', error);

        setSocket(ws);

        return () => {
            ws.close();
        };
    }, [intraday]);

    const combinedStocks = intraday.map((stock) => {
        const updatedStock = wsData.find((data) => data.tokenID === stock.tokenID);
        return updatedStock ? {...stock, ...updatedStock } : stock;
    });

    return (
        <div>
          <div className="politicalnewscontainer">
            {combinedStocks.map((intra, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "column",
                  padding: "10px",
                  marginBottom: "10px",
                  boxShadow: "0 0.115rem 0.25rem rgba(0, 0, 0, 0.075)",
                  borderRadius: "0.25rem",
                  backgroundColor: "#fff",
                  gap: "1rem",
                }}
              >
                {/* Main Row */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                  }}
                >
                  {/* Logo and Ticker */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      flexBasis: "45%",
                    }}
                  >
                    <img
      src={intra.logoUrl}
      alt="Nifty"
      style={{
        width: "40px",
        height: "40px",
        objectFit: "contain", // Ensures the image is not cropped
        backgroundColor: "#f0f0f0", // Optional: Add background color for empty space
      }}
    />
    
                    <div>
                      <p
                        style={{
                          fontSize: "clamp(0.75rem, 1vw, 1rem)",
                          fontWeight: 600,
                          margin: 0,
                          textTransform: "uppercase",
                        }}
                      >
                        {intra.ticker_code}
                      </p>
                      <p
                        style={{
                          fontSize: "clamp(0.7rem, 0.9vw, 1rem)",
                          margin: 0,
                          color: "#777D74",
                        }}
                      >
                        {intra.status}
                      </p>
                    </div>
                  </div>
      
                  {/* Buy Button */}
                  <div
                    style={{
                      padding: "4px 6px",
                      borderRadius: "8px",
                      backgroundColor: intra.Change > 0 ? "#d1ffdd" : "#ffdfe2",
                      color: intra.Change > 0 ? "#34a853" : "#dc3545",
                      fontSize: "clamp(0.7rem, 0.8vw, 0.9rem)",
                      cursor: "pointer",
                      textAlign: "center",
                      flexBasis: "14%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => handleNavigation(intra.ticker_code, intra.tokenID)}
                  >
                    Buy
                  </div>
      
                  {/* CMP and Change */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      textAlign: "right",
                      flexBasis: "30%",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "clamp(0.7rem, 1vw, 1rem)",
                        margin: 0,
                        color: "#777D74",
                        fontWeight: 500,
                      }}
                    >
                      {intra.LTP || "N/A"}
                    </p>
                    <p
                      style={{
                        display: "flex",
                        gap: "5px",
                        margin: 0,
                        fontSize: "clamp(0.7rem, 0.8vw, 0.9rem)",
                      }}
                    >
                      <span
                        style={{
                          color: intra.Change <= 0 ? "#dc3545" : "#34a853",
                        }}
                      >
                        {intra.Change !== undefined ? intra.Change.toFixed(2) : ""}
                      </span>
                      <span
                        style={{
                          color: intra.percentageChange <= 0 ? "#dc3545" : "#34a853",
                        }}
                      >
                        {intra.percentageChange !== undefined
                          ? `(${intra.percentageChange})`
                          : "N/A"}
                      </span>
                    </p>
                  </div>
      
                  {/* Dropdown Icon */}
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleDetails(index)}
                  >
                    <FaCaretDown />
                  </div>
                </div>
      
                {/* Dropdown Content */}
                {selectedIndex === index && (
                  <div
                    style={{
                      marginTop: "0px",
                      padding: "0px",
                      backgroundColor: "#f9f9f9",
                      borderRadius: "8px",
                      boxShadow: "0 0.005rem 0.15rem rgba(0, 0, 0, 0.075)",
                    }}
                  >
                    <table style={{ width: "80%", fontSize: "clamp(0.6rem, 0.7vw, 0.8rem)", color: "#212529" }}>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Entry Price</th>
                          <th>Target</th>
                          <th>Stop Loss</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{new Date(intra.created).toLocaleDateString()}</td>
                          <td>{intra.entryPrice}</td>
                          <td>{intra.targetPrice}</td>
                          <td>{intra.stopLossPrice}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      );};

export default Commodity;