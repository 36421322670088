import { CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SearchBox from './SearchBox';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import AnalystOption from './AnalystOption/AnalystOption';
import MarketTrends from './MarketTrends/MarketTrends';
import Mutualfunds from './Mutualfunds/Mutualfunds';
import ActiveFunds from './ActiveFunds/ActiveFunds';
import Financialnews from './Todays Financial News/Financialnews';
import EarningsCalender from './EarningsCalender/EarningsCalender';
import Carousel from './Carousel/Carousel';
import Footer from './Footer/Footer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useGetStocksQuery } from './redux/indexStocks';
import StockViewer from './Test';
import Currency from './Currency/Currency';
import LinearIndeterminate from './Assets/Load';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Commodity from './Commodity/Commodity';

const Grids = () => {
  const [alignment, setAlignment] = React.useState('Stocks');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const { data: singleData } = useGetStocksQuery();
  const stocks = singleData && singleData.stocks ? singleData.stocks : [];
  const currencies =
    singleData && singleData.currencies ? singleData.currencies : [];

  return (
    <div
      style={{
        top: '110px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '99%',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: isSmallScreen ? '0 10px' : '0 50px',
      }}
    >
      <div style={{ width: '95%', position: 'relative', marginTop: '20px' }}>
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{
            mt: isSmallScreen ? '37px' : 0,
            justifyContent: 'space-between',
            borderRadius: '20px',
          }}
        >
          <ToggleButton
            sx={{
              borderRadius: '20px',
              padding: '4px,10px',
              color: '#1a1818',
              fontSize: '11px',
              fontWeight: 'normal',
              border: '1px solid rgba(0, 0, 0, 0.12)!important',
              '&.MuiButtonBase-root': {
                padding: '8px',
              },
              '&.Mui-selected': {
                color: '#34a853',
                backgroundColor: '#d1ffdd',
                '&:hover': {
                  backgroundColor: '#d1ffdd',
                },
              },
              '&:not(.Mui-selected):hover': {
                backgroundColor: '#ffffff',
                color: '#34a853',
              },
            }}
            value="Stocks"
          >
            Stocks
          </ToggleButton>

          <ToggleButton
            sx={{
              borderRadius: '20px',
              padding: '4px,10px',
              fontSize: '11px',
              color: '#1a1818',
              fontWeight: 'normal',
              border: '1px solid rgba(0, 0, 0, 0.12)!important',
              '&.MuiButtonBase-root': {
                padding: '8px',
              },
              '&.Mui-selected': {
                color: '#34a853',
                backgroundColor: '#d1ffdd', // Background color when selected
                '&:hover': {
                  backgroundColor: '#d1ffdd', // Maintain selected background color on hover
                },
              },
              '&:not(.Mui-selected):hover': {
                backgroundColor: '#ffffff', // Hover color when not selected
                color: '#34a853',
              },
            }}
            value="Commodity"
          >
            Commodity
          </ToggleButton>

          <ToggleButton
            sx={{
              borderRadius: '20px',
              padding: '4px,10px',
              fontSize: '11px',
              color: '#1a1818',
              fontWeight: 'normal',
              border: '1px solid rgba(0, 0, 0, 0.12)!important',
              '&.MuiButtonBase-root': {
                padding: '8px',
              },
              '&.Mui-selected': {
                color: '#34a853',
                backgroundColor: '#d1ffdd', // Background color when selected
                '&:hover': {
                  backgroundColor: '#d1ffdd', // Maintain selected background color on hover
                },
              },
              '&:not(.Mui-selected):hover': {
                backgroundColor: '#ffffff', // Hover color when not selected
                color: '#34a853',
              },
            }}
            value="Currency"
          >
            Currency
          </ToggleButton>
        </ToggleButtonGroup>

        {/* StockViewer or Currency based on selection */}
        <div style={{ width: '95%', position: 'relative', marginTop: '10px' }}>
          {   alignment === 'Stocks' ? (
            <StockViewer />
          ) : alignment === 'Commodity' ? (
            <Commodity />
          ) : alignment === 'Currency' ? (
            <Currency />
          ) : (
            <LinearIndeterminate />
          )}
        </div>

        <SearchBox />

        <Grid
          sx={{ marginTop: '150px', position: 'static', zIndex: '-33' }}
          container
          spacing={2}
        >
          <Grid item xs={12} md={7} marginY={2}>
            <AnalystOption />
          </Grid>
          <Grid item xs={12} md={5} marginY={2}>
            <MarketTrends />
          </Grid>
          <Grid item xs={12} md={7} marginY={2}>
            <Mutualfunds />
          </Grid>
          <Grid item xs={12} md={5} marginY={2}>
            <ActiveFunds />
          </Grid>
          <Grid item xs={12} md={7} marginY={2}>
            <Financialnews />
          </Grid>
          <Grid item xs={12} md={5} marginY={2}>
            <EarningsCalender />
          </Grid>
          <Grid item xs={12} md={12} marginY={2}>
            <Carousel />
          </Grid>
          <Grid item xs={12} md={12} marginY={6}>
            <Footer />
          </Grid>
        </Grid>
      </div>
    </div >
  );
};

export default Grids;
