import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import Logindropdown from "./LoginModel/Logindropdown";
import Logoutdropdown from "./LoginModel/Logoutdropdown";

const navItems = ["Create Alert", "Create PortFolio"];
function LandingPage(props) {
  const { window } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [disable, setDisable] = React.useState(false);
  const [showLogout, setShowLogout] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [ssoCode, setSsoCode] = React.useState(null);

  console.log("-- We are hitting the Landing Page --");

  // Fetch the SSOCode from localStorage
  React.useEffect(() => {
    const storedData = localStorage.getItem("SSOCode");
    if (storedData) {
      setSsoCode(storedData);
    } else {
      console.error("SSO Code not found in localStorage");
    }
    console.log("This is landing page SSO DATA -->", storedData);
  }, [ssoCode]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    if (localStorage.getItem("verifiedData")) {
      setShowLogout(true);
    } else {
      setShowLogout(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHomenav = () => {
    navigate("/");
  };

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <Box
        sx={{
          display: "inline-block",
          position: "fixed",
          zIndex: "999",
          backgroundColor: "#ffffff",
          paddingBottom: "20px",
          borderBottom: "1px solid #d4d4d4",
          width: "100%",
          height: "90px",
          top: "-12px",
        }}
      >
        <CssBaseline />
        <AppBar
          sx={{
            backgroundColor: "#fff",
            boxShadow: "none",
            color: "black",
          }}
          component="nav"
        >
          <Toolbar
            sx={{ position: "absolute", top: "12px", cursor: "pointer" }}
          >
            <img
              onClick={handleHomenav}
              src="https://adityatrading.in/static/images/logo.png"
              alt="Ats"
              style={{
                width: "107px",
                height: "47px",
                cursor: "pointer",
                display: "inline-block",
                zIndex: "333",
              }}
            />
          </Toolbar>
          <Box
            sx={{
              position: "relative",
              justifyContent: "end",
              top: "25px",
              marginRight: "140px",
              display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              {ssoCode &&
                navItems.map((item) => {
                  const handleLink = () => {
                    const baseUrl = `https://adityatrading.in/SSO=${ssoCode}/?type=`;
                    if (item === "Create Alert") {
                      return `${baseUrl}create_alert`;
                    } else if (item === "Create PortFolio") {
                      return `${baseUrl}create_portfolio`;
                    }
                    return "#";
                  };
                  return (
                    <Typography
                      key={item}
                      component="a"
                      href={handleLink()}
                      sx={{
                        fontSize: "14px",
                        color: "#777D74",
                        textDecoration: "none",
                        "&:hover": {
                          color: "#81c784",
                          cursor: "pointer",
                        },
                      }}
                    >
                      {item}
                    </Typography>
                  );
                })}
            </Box>

          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleClick}
            disabled={disable}
            sx={{
              ml: 2,
              fontSize: "14px",
              display: "inline-block",
              position: "absolute",
              right: { xs: "10%", md: "10%", lg: "5%", sm: "10%" },
              top: "25px",
            }}
          >
            <MenuIcon
              sx={{ fontSize: "14px", height: "17px", width: "17px" }}
            />
          </IconButton>
        </AppBar>
        {showLogout ? (
          <Logoutdropdown
            anchorEl={anchorEl}
            handleClose={handleClose}
            open={Boolean(anchorEl)}
          />
        ) : (
          <Logindropdown
            anchorEl={anchorEl}
            handleClose={handleClose}
            open={Boolean(anchorEl)}
          />
        )}
      </Box>
    </div>
  );
}

LandingPage.propTypes = {
  window: PropTypes.func,
};

export default LandingPage;
