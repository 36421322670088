import './App.css';

import { Route, Routes } from 'react-router-dom';
import CreateAlert from './CreateAlert/CreateAlert';
import HomePage from './Home/HomePage';
import CreatePortFolio from './CreatePortfolio/CreatePortFolio';
import Signin from './Signin/Signin';
import AccountOpen from './Account/AccountOpen';
import ElementCalculator from './Calculator/ElementCalculator';
import PremiumCalculator from './Calculator/PremiumCalculator';
import BrokerageCalculator from './Calculator/BrokerageCalculator';
import Charges from './Charges/Charges';
import Contact from './ContactUs/Contact';
import Createticket from './UiPages/CreateTicket/Createticket';
import InvestmentPhilosophy from './UiPages/Investment/InvestmentPhilosophy';
import RegisteredBankDetails from './UiPages/BankDetails/RegisteredBankDetails';
import Careers from './UiPages/Careers/Careers';
import Branches from './UiPages/Branches/Branches';
import MorningReports from './UiPages/Research/MorningReports';
import InvestorGreivance from './UiPages/InvestorCorner/InvestorGreivance';
import AccountOpenProcess from './UiPages/InvestorCorner/AccountOpen';
import FormCenterResource from './UiPages/InvestorCorner/FormCenterResource';
import KycChecklist from './UiPages/InvestorCorner/KycChecklist';
import AtsPolicy from './UiPages/InvestorCorner/AtsPolicy';
import FileComplaint from './UiPages/InvestorCorner/FileComplaint';
import MarketCalender from './UiPages/Markets/MarketCalender';
import Posts from './UiPages/OtherReports/Posts';
import PostDetails from './UiPages/OtherReports/PostDetails';
import Circular from './UiPages/Circular/Circular';
import Mutualfunds from './UiPages/OtherReports/Mutualfunds';
import Analystopinion from './UiPages/OtherReports/Analystopinion';
import Dps from './UiPages/Investorcharter/Dps';
import Stockbroker from './UiPages/Investorcharter/Stockbroker';
import DpsMonthly from './UiPages/MonthlyDisclosur/DpsMonthly';
import StockMonthly from './UiPages/MonthlyDisclosur/StockMonthly';
import CompanyPages from './Company/CompanyPages';
import Theft from './theft/Theft';
import MorningReportsDetails from './UiPages/Research/MorningReportsDetails';

import Currency from './Currency/Currency';
import JobDetails from './UiPages/Careers/JobDetails';
import VerifyOtp from './Signin/VerifyOtp';
import UpdateInfo from './Signin/UpdateInfo';
import Tes2 from './TechnicalAnalysis';
import ChartComponent from './Tes3';
import CompanyInfo from './Company/CompanyInfo';
import CompanyData from './Company/CompanyInfo';
import St from './test/St';
import TechnicalAnalysis from './TechnicalAnalysis';
import Overview from './Company/Overview';
import UserProfile from './UiPages/User/UserProfile';
import FooterMainPage from './FooterButtons/footermain';
import Disclaimer from './FooterButtons/Disclaimer';
import PrivacyPolicy from './FooterButtons/PrivacyPolicy';
import { useEffect } from 'react';
import HashidsEncoder from './ssoencryptor';
import { WebSocketProvider } from './websocket';

function App() {

  // Initialize SSO encryption on app load
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('verifiedData'));

    if (storedData && storedData.Phone_Number) {
      const hashEncoder = new HashidsEncoder();
      const encodedClientID = hashEncoder.encodeClientID(storedData.Phone_Number);

      // Save the encoded SSO back to localStorage
      localStorage.setItem('SSOCode', encodedClientID);
      console.log('Generated and stored SSO Code:', encodedClientID);
    } else {
      console.warn('No valid `verifiedData` found in localStorage.');
    }
  }, []);

  console.log('----------------->SSOCode', localStorage.getItem('SSOCode'));

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/create_alert" element={<CreateAlert />} />
      <Route path="/create_portfolio" element={<CreatePortFolio />} />
      <Route path="/sign-in/*" element={<Signin />} />
      <Route path="/account-opening/*" element={<AccountOpen />} />
      <Route path="/margin-calculator/*" element={<ElementCalculator />} />
      <Route path="/premium-calculator/*" element={<PremiumCalculator />} />
      <Route
        path="/brokerage_calculator/"
        element={<BrokerageCalculator />}
      />
      <Route path="/charges/*" element={<Charges />} />
      <Route path="/contactus/*" element={<Contact />} />
      <Route path="/createTicket/*" element={<Createticket />} />
      <Route
        path="/investment-philosophy/*"
        element={<InvestmentPhilosophy />}
      />
      <Route
        path="/bank-account-details/*"
        element={<RegisteredBankDetails />}
      />
      <Route path="/careers/*" element={<Careers />} />
      <Route path="/branches/*" element={<Branches />} />
      <Route path="/share-market-news/" element={<MorningReports />} />
      <Route
        path="/share-market-news/details"
        element={<MorningReportsDetails />}
      />
      <Route path="/investment-grievance/*" element={<InvestorGreivance />} />
      <Route
        path="/account-opening-process/*"
        element={<AccountOpenProcess />}
      />
      <Route
        path="/form-center-resources/*"
        element={<FormCenterResource />}
      />
      <Route path="/kyc-checklist/*" element={<KycChecklist />} />
      <Route path="/ats-policy/*" element={<AtsPolicy />} />
      <Route path="/how-to-file-complaint/*" element={<FileComplaint />} />
      <Route path="/holiday-calendar/*" element={<MarketCalender />} />
      <Route path="/posts/*" element={<Posts />} />
      <Route path="/posts/detail" element={<PostDetails />} />
      <Route path="/circulars/" element={<Circular />} />
      <Route path="/mutual-funds/*" element={<Mutualfunds />} />
      <Route path="/analyst_opinions/" element={<Analystopinion />} />
      <Route path="/investor-charter-dp/" element={<Dps />} />
      <Route
        path="/investor-charter-stock-broker/"
        element={<Stockbroker />}
      />
      <Route path="/monthly-disclosure-dp/" element={<DpsMonthly />} />
      <Route
        path="/monthly-disclosure-stockbroker/"
        element={<StockMonthly />}
      />
      <Route path="/companypage/colpal/" element={<Overview />} />
      <Route path="/theft-identity/" element={<Theft />} />
      <Route path="/test" element={<St />} />
      <Route path="/careers/JobDetails" element={<JobDetails />} />
      <Route path="/verify-otp/" element={<VerifyOtp />} />
      <Route path="/update-user" element={<UpdateInfo />} />
      <Route path="test12" element={<TechnicalAnalysis />} />
      <Route path="test13" element={<ChartComponent />} />

      <Route path="/tnc/" element={<FooterMainPage />} />
      <Route path="/desc/" element={<Disclaimer />} />
      <Route path="/privacypolicy/" element={<PrivacyPolicy />} />

      {/* <Route path="/tnc-buttons" element={<TncButtons />} />
      <Route path="/client-registration" element={<ClientRegistrationPage />} />
      <Route path="/other-policy" element={<OtherPolicyPage />} />
      <Route path="/risk-management-policy" element={<RiskManagementPolicyPage />} /> */}

      <Route path="companyPage/:comapnyName" element={<CompanyData />} />
      <Route path="/userProfile" element={<UserProfile />} />
    </Routes>
  );
}

export default App;
